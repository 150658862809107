import {
  UNCHECKED, INDETERMINATE, CHECKED,
} from '../constants'

import {
  createMap,
} from '../utils'

export default {
  methods: {

    buildForestState() {
      const selectedNodeMap = createMap()
      this.forest.selectedNodeIds.forEach(selectedNodeId => {
        selectedNodeMap[selectedNodeId] = true
      })
      this.forest.selectedNodeMap = selectedNodeMap

      const checkedStateMap = createMap()
      if (this.multiple) {
        this.totalCount = 0
        this.traverseAllNodesByIndex(node => {
          checkedStateMap[node.id] = UNCHECKED
          node.isExpanded = false
        })

        this.selectedNodes.forEach(selectedNode => {
          if (selectedNode.parentNode) {
            selectedNode.parentNode.isExpanded = true
          }

          checkedStateMap[selectedNode.id] = CHECKED

          if (
            (selectedNode.parentNode != null && this.localSearch.active &&
              checkedStateMap[selectedNode.parentNode.id] === UNCHECKED)
            || (selectedNode.level > 0 || selectedNode.children === undefined)) {
            this.totalCount += 1
          }

          if (!this.flat && !this.disableBranchNodes) {
            selectedNode.ancestors.forEach(ancestorNode => {
              if (!this.isSelected(ancestorNode)) {
                checkedStateMap[ancestorNode.id] = INDETERMINATE
              }
            })
          }
        })
      }

      this.forest.checkedStateMap = checkedStateMap
    },
  },
}
